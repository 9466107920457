import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'SecurityDefinitionForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "security-definition-form"
    }}>{`Security Definition Form`}</h2>


    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'name',
      type: 'text'
    }, {
      name: 'type',
      type: 'select'
    }, {
      name: 'message',
      type: 'text'
    }, {
      name: 'rule',
      type: 'code'
    }]} formOptions={[{
      name: 'securityPolicyName',
      type: 'string',
      required: true,
      description: 'The slug for the security to be updated/created.'
    }, {
      name: 'kappSlug',
      type: 'string',
      description: 'Should be provided when interacting with security definitions within a kapp.'
    }]} dataSources={[{
      name: 'space',
      type: 'space'
    }, {
      name: 'kapp',
      type: 'kapp'
    }, {
      name: 'securityPolicy',
      type: 'securityPolicyDefinition'
    }, {
      name: 'profile',
      type: 'profile'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      