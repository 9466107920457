import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`Via `}<a parentName="p" {...{
        "href": "https://npmjs.com/package/@kineticdata/react"
      }}>{`npm`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @kineticdata/react
`}</code></pre>
    <p>{`Via `}<a parentName="p" {...{
        "href": "http://yarn.fyi/@kineticdata/react"
      }}>{`Yarn`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add @kineticdata/react
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`KineticLib`}</inlineCode>{` Provider provides a global context to your site or applications connection to the Kinetic Platform and
is required in order to render components within this library.`}</p>
    <h3 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h3>
    <p>{`Below is an example of how you would wrap your project with the `}<inlineCode parentName="p">{`KineticLib`}</inlineCode>{` provider.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/**
 * /src/App.js
 */

import React from 'react';
import { KineticLib } from '@kineticdata/react';
import { Router, Route, Switch } from 'react-router-dom';
import Space from './Space';
import Kapp from './Kapp'; // see the Kapp component defined in \`API\` example below

const App = () => (
  <KineticLib>
    {({ initialized, loggedIn, loginProps }) => (
      <Router>
        {!initialized ? (
          <LoginLoading />
        ) : !loggedIn ? (
          <LoginScreen {...loginProps} />
        ) : (
          <Switch>
            <Route path="/" component={Space} />
            <Route path="/kapps/:kappSlug" component={Kapp} />
          </Switch>
        )}
      </Router>
    )}
  </KineticLib>
);

export default App;
`}</code></pre>
    <h3 {...{
      "id": "example-api-usage"
    }}>{`Example API Usage`}</h3>
    <p>{`All Service API's are exposed as functions to perform CRUD operations within the platform.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import { fetchKapp } from '@kineticdata/react';

class Kapp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { kapp: {} };
  }

  componentDidMount() {
    const { kapp } = fetchKapp(this.props.kappSlug, {
      include: 'details,forms',
    });
    this.setState({ kapp });
  }

  render() {
    const { kapp } = this.state;
    return (
      <div>
        <h1>{kapp.slug}</h1>
        <ul>
          {kapp.forms.map(form => (
            <li>{form.name}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Kapp;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      