import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kinetic-react-developers"
    }}>{`Kinetic React Developers`}</h1>
    <h4 {...{
      "id": "provided-in-this-library-are-a-number-of-components-that-allow-can-be-used-within-a-project-to-enable-speedy-development-within-the-kinetic-platform"
    }}>{`Provided in this library are a number of components that allow can be used within a project to enable speedy development within the Kinetic Platform.`}</h4>
    <ul>
      <li parentName="ul">{`Core Components: Interact with the Core Service`}</li>
      <li parentName="ul">{`Form Components: A generic component that can be used for rendering forms`}</li>
      <li parentName="ul">{`Table Components: A generic component that can be used for rendering tables`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      