import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { FormProps } from '../../components/FormProps';
export const name = 'TeamForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <FormProps fields={[{
      name: 'parentTeam',
      type: 'team'
    }, {
      name: 'localName',
      type: 'text'
    }, {
      name: 'name',
      type: 'text'
    }, {
      name: 'description',
      type: 'text'
    }, {
      name: 'attributesMap',
      type: 'attributes'
    }, {
      name: 'memberships',
      type: 'user-multi'
    }]} formOptions={[{
      name: 'teamSlug',
      type: 'string',
      description: 'When provided the form will be populated by retrieving the corresponding team and will update that team when submitted. If not provided the form submission will create a new team.'
    }]} dataSources={[{
      name: 'attributeDefinitions',
      type: 'AttributeDefinition[]'
    }, {
      name: 'team',
      type: 'Team'
    }]} mdxType="FormProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      