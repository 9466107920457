import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "geticon"
    }}>{`getIcon`}</h1>
    <p>{`Certain objects can have custom icons used to represent them in menus and in cards. The icon is
assumed to be one of the Font Awesome icons. It looks at the object for an `}<inlineCode parentName="p">{`'Icon'`}</inlineCode>{` attribute.
If a default value override is not provided and an icon is not configured it will send the icon `}<inlineCode parentName="p">{`'square-o'`}</inlineCode>{`
back by default. If a configured icon starts with `}<inlineCode parentName="p">{`'fa-'`}</inlineCode>{` it will strip that off.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

// Return a string containing the name of the icon for the item.
Utils.getIcon(kapp, 'couch');
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      