import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getprofileattributevalue"
    }}>{`getProfileAttributeValue`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`getProfileAttributeValue`}</inlineCode>{` function is used to fetch an attribute value out of either an attribute list or map. It takes the
'context' of the attributes, for example `}<inlineCode parentName="p">{`user`}</inlineCode>{` or `}<inlineCode parentName="p">{`profile`}</inlineCode>{`, an attribute name, and a default value. Because it supports both
a list and a map of attributes it doesn't matter which way you include attributes on your parent object API request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

Utils.getProfileAttributeValue(user, 'Color', 'Blue');
// returns either the Color attribute or 'Blue' if that attribute is missing or empty.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      