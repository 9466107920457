import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1339663828/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generic-message-support"
    }}>{`Generic Message Support`}</h1>
    <p>{`This component is not directly accessible by bundle and package developers but is the basis of the support
for the other message components. The `}<inlineCode parentName="p">{`className`}</inlineCode>{` prop modifies the `}<strong parentName="p">{`base`}</strong>{` class name used for the styling
of the content. For example, setting `}<inlineCode parentName="p">{`className`}</inlineCode>{` to "data-loader" makes the content use the following classes:
`}<inlineCode parentName="p">{`data-loader__heading`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-loader__title`}</inlineCode>{`, `}<inlineCode parentName="p">{`data-loader__message`}</inlineCode>{`, and `}<inlineCode parentName="p">{`data-loader__actions`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <pre><code parentName="pre" {...{}}>{`* className -> string = Allows overriding the base class name.
* heading -> string = optional heading text.
* title -> string = optional title text.
* message -> optional message displayed below the title.
* actions -> Action[] -> an array of actions to be rendered at the bottom of the message.
  Action - note: actions require a label or icon and an onClick handler to be valid.
  * icon -> string = Font Awesome icon to be shown in the action button.
  * label -> string = label to be shown in the action button
  * onClick -> (ReactNativeEvent) => null = function called when action button is clicked.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      